import useSoundLibrary from "use-sound";
import { soundSetting as serviceSoundSetting } from "service";
import {
  	BigWheelLineWin,
	BigWheelGremlinLaugh,
} from "assets/audio";

export const useSound = (soundEnabled) => {
  const soundSetting = {
    ...serviceSoundSetting,
    soundEnabled
  };
  const [playLineWin, { stop: stopLineWin }] = useSoundLibrary(BigWheelLineWin, soundSetting);

  const [playLaugh, { stop: stopLaugh}] = useSoundLibrary(BigWheelGremlinLaugh, {
		...soundSetting,
		autoplay: false
	});

  return {
		playLaugh,
		playLineWin,
		stopLineWin,
		stopLaugh,
  };
}

export default useSound